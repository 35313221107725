<template>
  <div class="loan_item">
    <div class="inner">
      <h3>Smart Credit Score</h3>
      <div class="left_cell">
        <p>SmartCreditScore, brings alternate data and machine learning together for businesses that are looking to get instant data – instant insights – instant decisions.</p>
        <p>SmartCreditScore powers instant decisions for NBFCs, Banks, Wallets and E-Commerce,our partnerships with credit information companies Experian and CIBIL, respectively, to offer free credit reports, made your lending more efficient. We help customers get instant loans in less than a minute from 0 to Loan Approvals.</p>
        <p>You need generate SmartCreditScore before loan in PesoSafe. Based on your SmartCreditScore, we will recommend loan products to meet your requirements .We hope you will repay the loan as promised, otherwise your default record will lead to a reduction in your credit score and you will not be able to obtain a loan.</p>
        <!-- <ul>
          <li>No credit history<br>required</li>
          <li>100% online</li>
        </ul> -->
      </div>
      <div class="right_cell">
        <img src="@/assets/images/loanImg/bn_1.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 36px;
      color: #333333;
    }
    .left_cell{
      float: left;
      width: 50%;
      padding: 60px 0;
      p{
        font-size: 16px;
        line-height: 30px;
        color: #999999;
      }
      ul{
        padding-top: 60px;
        li{
          float: left;
          width: 188px;
          height: 132px;
          padding-top: 50px;
          text-align: center;
          font-size: 16px;
          border-radius: 4px;
          border: 1px solid #f1f1f1;
          color: #333333;
          box-shadow: 0px 1px 10px 1px #f1f1f1;
          margin-right: 40px;
          margin-left: 5px;
        }
      }

    }
    .right_cell{
      float: left;
      width: 50%;
      padding: 60px 0;
      img{
        width: 560px;
      }

    }
  }

}
</style>
